/* Styles/productdetails.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color:ivory;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.product-imgs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.img-showcase img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  width: fit-content;
  margin-top:5%;
}

.product-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.product-price {
  font-size: 20px;
  color: #e74c3c;
  margin-bottom: 20px;
}

.purchase-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #2980b9;
  color: #ffffff;
}

.Toastify__toast-container {
  font-size: 14px;
}
