.wel-pg {
  background-color: #dbfcff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
}

.wel-pg .overlap-wrapper {
  background-color: #dbfcff;
  height: 1024px;
  width: 1440px;
}

.wel-pg .overlap {
  height: 993px;
  position: relative;
  top: 31px;
}

.wel-pg .element {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 90px;
  width: 1370px;
  height: 575px;
  flex-shrink: 0;
}

.wel-pg .group {
  height: 84px;
  left: 993px;
  position: absolute;
  top: 468px;
  width: 181px;
}

.wel-pg .text-wrapper {
  color: #000000;
  font-family: 'Sniglet-Regular', Helvetica;
  font-size: 35px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 16px;
  white-space: nowrap;
  width: 115px;
}

.wel-pg .img {
  height: 5px;
  left: 1004px;
  position: absolute;
  top: 468px;
  width: 19px;
}

.wel-pg .div-wrapper {
  height: 84px;
  left: 775px;
  position: absolute;
  top: 468px;
  width: 179px;
}

.wel-pg .welcome {
  color: #000000;
  font-family: 'Sniglet-Regular', Helvetica;
  font-size: 157px;
  font-weight: 400;
  left: 133px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 867px;
}

.wel-pg .span {
  color: #000000;
  font-family: 'Sniglet';
  font-size: 90px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -20px;
}

.wel-pg .text-wrapper-4 {
  font-size: 26px;
}

.wel-pg .text-wrapper-5 {
  color: #000000;
  font-family: 'Sniglet';
  font-size: 51.5px;
  font-weight: 400;
  left: 270px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 70px;
  width: 820px;
}

.wel-pg .text-wrapper-6 {
  color: #000000;
  font-family: 'Sniglet';
  font-size: 45px;
  font-weight: 400;
  left: 280px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  width: 204px;
  margin-top: 35px;
}

.wel-pg .p {
  color: #000000;
  font-family: 'Sniglet', Helvetica;
  font-size: 23px;
  font-weight: 300;
  left: 630px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 210px;
  flex-shrink: 0;
}
.store-w {
  position: relative;
  top: 325px;
  left: 1000px;
}
.prod-w {
  position: relative;
  top: 367px;
  left: 890px;
}
