@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

.addproduct {
  background-color: #dbfcff;
}

.signtop1 {
  width: 274px;
  height: 35px;
  position: relative;
  top: 0px;
  left: -6px;
}

.signtop2 {
  width: 290px;
  height: 35px;
  position: relative;
  top: 0px;
  left: 5.5px;
}

.container-addproduct {
  width: 575px;
  height: 1050px;
  flex-shrink: 0;
  padding: 40px 0;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Sniglet', sans-serif;
  border-top: 20px solid #005e89;
  border-bottom: 60px solid #005e89;
  border-left: 20px solid #005e89;
  border-right: 20px solid #005e89;
  border-radius: 0px;
}

h5.rectagles {
  width: 265px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #005e89;
  top: -40px;
  left: 10px;
  position: relative;
}

h2 {
  color: #6c6363;
  font-family: 'Sniglet', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 176px;
  height: 58px;
  flex-shrink: 0;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  display: flex;
  color: rgba(48, 48, 48, 0.81);
  text-align: left;
  font-family: 'Sniglet', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}

input,
textarea,
button {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #c6923b;
  color: #fff;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-msg {
  color: #dc3545;
}

.success-msg {
  color: #28a745;
  font-weight: bold;
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}
.spinner {
  width: 88px;
  height: 88px;
  display: grid;
  border: 7px solid #0000;
  border-radius: 50%;
  border-right-color: #474bff;
  animation: spinner-a4dj62 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: '';
  grid-area: 1/1;
  margin: 3.5px;
  border: inherit;
  border-radius: 50%;
  animation: spinner-a4dj62 2s infinite;
}

.spinner::after {
  margin: 14px;
  animation-duration: 3s;
}

@keyframes spinner-a4dj62 {
  100% {
    transform: rotate(1turn);
  }
}
