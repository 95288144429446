@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

.sub-navbar {
  background-color: #333;
  padding: 10px 0;
}

ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

li {
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
}

li:hover {
  background-color: #555;
}
