/* Base Styles */
@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

body {
  font-family: 'Sniglet', sans-serif;
  background-color: #dbfcff; /* Add a default background for better UI */
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px; /* Add padding for better spacing on smaller screens */
}

header {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 24px;
}

/* Responsive Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Sniglet', sans-serif;
}

main {
  margin-top: 20px;
}

/* User Info */
.user-info {
  text-align: center;
  margin-top: 20px;
}

.user-info p {
  margin: 10px 0;
}

.loading {
  font-family: 'Sniglet', sans-serif;
  color: #888;
}

.dash {
  font-size: 40px; /* Reduce font size for smaller screens */
  text-align: center;
  color: #000;
  margin: 20px 0;
}

/* Dashboard Container */
.dashboard-container {
  display: flex;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Section Styles */
.lab, .lab1 {
  width: 100%;
  max-width: 340px; /* Limit width for smaller screens */
  height: auto;
  background: #EEC361;
  border-radius: 10px;
  padding: 15px;
}

.lab1 {
  background: #78D0D9;
}

.addp1, .addp2 {
  font-size: 16px;
  text-align: center;
}

.product-container {
  display: flex;
  flex-direction: column; /* Stack items for mobile */
  gap: 10px;
  align-items: center;
}

.product-item123 {
  width: 100%;
  max-width: 320px;
  height: auto;
  background: #F2FDFD;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.product-image {
  width: 100%;
  max-width: 80px;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

/* My Products Section */
.product-name-container {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-name {
  color: #303030;
  font-size: 16px;
}

#linkfont {
  font-size: 14px;
  color: rgba(21, 73, 255, 0.81);
  text-align: center;
}

/* Small Boxes Section */
.small-boxes-containerq {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.smallbox1, .smallbox2, .smallbox31 {
  width: 30px;
  height: 20px;
  background: #EEC361;
  border-radius: 5px;
}

/* Earnings Section */
.earnings {
  width: 100%;
  max-width: 340px;
  background: #005E89;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

/* Borrow Section */
.borrow-now {
  width: 100%;
  max-width: 340px;
  background: #EEC361;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
}

/* User Info Box */
.user-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.cust-idoutline {
  background: #EEC361;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.cust-id {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}

/* Buttons */
#show1, #show {
  width: 100%;
  max-width: 230px;
  padding: 10px 15px;
  border-radius: 15px;
  background: #005E89;
  color: #fff;
  text-align: center;
  font-size: 18px;
  margin: 10px auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dash {
    font-size: 32px;
  }

  .lab, .lab1, .borrow-now, .earnings {
    width: 100%;
  }

  .product-container {
    flex-direction: column;
  }

  .user-info-container {
    align-items: center;
    margin: 10px auto;
  }

  .dashboard-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  header {
    font-size: 18px;
  }

  .dash {
    font-size: 24px;
  }

  .lab, .lab1, .borrow-now, .earnings, .cust-idoutline {
    padding: 10px;
  }

  .product-name {
    font-size: 14px;
  }
}
