.container-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  padding: 20px;
  border-radius: 15px;
  border: 4px solid #4912ea;
  background: #fff;
  margin: auto;
  margin-top: 50px;
  position: relative;
}

.container-profile-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-icon {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-icon img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.profile-title, .profile-data {
  width: 315px;
  margin-bottom: 10px;
}

.profile-title {
  font-family: 'Oldenburg', cursive;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 201, 177, 0.75);
}

.profile-data {
  background: rgba(181, 253, 245, 0.75);
  color: rgba(2, 110, 97, 0.75);
  text-align: left;
  border-radius: 5px;
  font-family: 'Oldenburg', cursive;
  font-size: 18px;
  padding: 10px;
}

.profile-edit-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(7, 54, 210, 0.521);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.profile-edit-button:hover {
  background: rgba(0, 64, 201, 0.75);
}

.profile-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}
