@import url('https://rsms.me/inter/inter.css');
@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Sniglet', sans-serif;
  }
}

body {
  background-color: #f0f0f0; /* Change to your desired background color */
}

.actions {
  font-family: 'Sniglet', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}

h1 {
  margin-bottom: 1rem;
  font-size: 30px;
  color: #000;
  font-family: Sniglet;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.field__inputs {
  border-radius: 20px;
  background: #689a9e;
}

.fields {
  color: #57898d;
  font-family: Sniglet;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}

p {
  color: var(--color-gray);
}

hr {
  height: 1px;
  width: 100%;
  background-color: var(--color-light-gray);
  border: 0;
  margin: 2rem 0;
}

.container-sh {
  position: absolute;
  top: 330px;
  padding-bottom: 2%;
  left: 55px;
  border-radius: 20px;
  background: #b4eaef;
  box-shadow: 0px 10px 20px 0px rgba(69, 129, 135, 0.5);
  width: 755px;
  height: 730px;
  flex-shrink: 0;
}

.sbg {
  position: absolute;
  top: 250px;
  left: 0;
  width: 860px;
  height: 890px;
  z-index: -1; /* Ensure the image is positioned behind other content */
}

.cont-s {
  display: 'flex';
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-lighter-gray);
  padding: 0.4rem;
  font-family: 'Sniglet';
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.field__label {
  color: var(--color-gray);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

.field__input {
  color: var(--color-gray);
  padding: 0.5rem;
  margin: 0;
  border: 0;
  outline: 0;
  font-weight: bold;
  font-size: 0.7rem;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}

.fields {
  display: grid;
  grid-gap: 1rem;
}

.button-container {
  margin-top: 1rem;
  display: flex; /* Added for proper alignment */
  justify-content: center; /* Center the button horizontally */
}

.button {
  display: block;               /* Ensures block-level alignment */
  margin: 20px auto;            /* Centers the button horizontally */
  width: 50%;                   /* Adjust button width */
  padding: 10px 0;              /* Add consistent padding */
  text-align: center;           /* Center the text inside the button */
  background-color: #000;       /* Button background color */
  color: #fff;                  /* Text color */
  font-size: 1rem;              /* Text size for better readability */
  font-weight: 600;             /* Bold text */
  border: none;                 /* Remove border */
  border-radius: 8px;           /* Rounded corners */
  cursor: pointer;              /* Pointer cursor for hover effect */
}

.button:focus, .button:hover {
  background-color: #333;       /* Darker shade on hover/focus */
}

/* Responsive Adjustment for the Button */
@media (max-width: 768px) {
  .button {
    width: 80%;                 /* Wider button for smaller screens */
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5%;
  color: #6b72d6;
  font-family: 'Sniglet', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10%;
  padding-right: 25%;
}

.vertical-space-container {
  flex-grow: 1;
  margin-left: auto;
  width: 10%;
}

.outerbox {
  padding: 2rem;
}

.cont {
  display: 'flex';
}

.side_cont {
  position: relative;
  width: 120%;
  height: 120px;
}

.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
}

.container1 {
  position: absolute;
  top: 40%;
  right: 5%;
  width: 30%;
  height: 100%;
  padding-bottom: 70%;
  margin-bottom: 30%;
  border: 1px solid var(--color-lighter-gray);
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: rgb(0, 94, 137);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cart-item33 {
  background-color: rgb(229, 224, 217);
}

.container1 .product-details {
  display: flex;
  flex-direction: column;
  background-color: d4e2f3;
}

.container1 .product-details .head h6 {
  font-size: 20px;
  margin-bottom: 0.5rem;
  background-color: d4e2f3;
}

.container1 .product-details .cost p {
  margin: 0;
  padding: 0.5rem 0;
  background-color: d4e2f3;
  border-bottom: 1px solid var(--color-light-gray);
}

.container1 .product-details .cost:last-child p {
  border-bottom: none;
  font-weight: bolder;
}

.cost {
  width: auto;
}

.payment-label {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.payment-label .label-text {
  font-size: 1rem;
  font-weight: medium;
  margin-bottom: 0.4rem;
  flex: 1;
  padding-right: 55%;
}

.payment-label .label-value {
  font-size: 0.9rem;
  color: #6b6b6b;
  text-align: right;
  flex-shrink: 0;
}

.payment-total {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.payment-total .total-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.payment-total .total-value {
  font-size: 1.5rem;
  color: #000;
  text-align: right;
  flex-shrink: 0;
}
