@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

.side-navigation-bar {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #599bb3;
  overflow-x: hidden;
  padding-top: 20px;
  padding-left: 10px;
}

label {
  display: inline-block;
  margin-right: 5px;
}

input[type='range'],
input[type='checkbox'] {
  margin-top: 5px;
  align-items: right;
}

h3 {
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 16px;
}

.range-values {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  color: #160672;
}
label[for='minInput'],
label[for='maxInput'] {
  display: inline-block;
  width: 30px;
  margin-left: 4px;
}

input[type='number'] {
  width: 8px;
}
