@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

.totf {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Ensure the footer takes the full width */
}

.div {
  background: #2f6d9a;
  display: flex;
  justify-content: space-between; /* Distribute space between left and right content */
  align-items: center; /* Center items vertically */
  padding: 50px 77px;
  box-sizing: border-box; /* Include padding in element's total width and height */
}

@media (max-width: 991px) {
  .div {
    flex-wrap: wrap;
    padding: 0 20px;
  }
}

.logo-container {
  display: flex;
  align-items: center; /* Center the logo vertically */
  margin-right: 20px; /* Add space between the logo and text content */
}

.logo {
  width: 100px;
  height: 80px;
}

.feedback-container {
  display: flex;
  align-items: center; /* Align feedback links vertically centered */
  gap: 20px; /* Add space between each link */
}

.feedback-link {
  color: #fff; /* Ensure link color is white */
  text-decoration: none;
  font-weight: bold;
  position: relative; /* For adding a background box effect */
  overflow: hidden; /* Hide overflow for the pseudo-element */
  transition: color 0.3s, background-color 0.3s; /* Smooth transition for color and background color */
  padding: 5px 10px; /* Add padding for better visual effect */
}

.feedback-link:hover {
  color: #ffffff; /* Change text color on hover */
  background-color: rgba(255, 255, 255, 0.2); /* Add a semi-transparent background color */
  border-radius: 5px; /* Add rounded corners to the background box */
}

.social-buttons {
  display: flex;
  justify-content: flex-end; /* Align social icons to the right */
  gap: 30px; /* Add space between icons */
}

.social-buttons a {
  color: #fff; /* White color for icons */
  text-decoration: none;
  font-size: 24px; /* Increase font size to make icons larger */
  transition: color 0.3s, transform 0.3s; /* Smooth transition for color and scaling */
}

.social-buttons a:hover {
  color: #d3d0d0; /* Color change on hover */
  transform: scale(1.5); /* Slightly scale up on hover */
}

.element {
  margin-top: -50px;
  background-color: #2f6d9a;
  object-fit: fill;
  width: 100%; /* Adjust width as needed */
  z-index: -1;
}
