@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}
.product-page1 {
  display: flex;
}

.sidenav {
  width: 200px; /* Adjust the width of the side navbar */
 
  /* Add other styles as needed */
}


.product-list-image {
  width: 100px;
  height: auto;
  max-height: 100px;
  object-fit: cover;
}

.product-list {
  display: flex;
right: 40%;
margin-top: 60px;
row-gap: 20px;
margin-left: 30px;
margin-right: 30px;
margin-bottom: 30px;
 
  flex: 1;
 
}

.product-item {
  list-style: none;
  border-left: 30px solid #EEC361;
  border-right: 30px solid #EEC361;
  border-top: 30px solid #EEC361;
  border-bottom: 30px solid #EEC361;
  padding-top: 10px;
  flex: 1 0 calc(30% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  
 
}
.smallbox1{
  width: 35.225px;
height: 24.611px;
flex-shrink: 0;
background: #EEC361;

}
.small-boxes-containerq {
  display: flex;
  justify-content: space-around; /* Adjust as needed for spacing between boxes */
}
.product-item:hover {
  transform: scale(1.01);
 
}

.product-image-container {
  overflow: hidden;
  height: 150px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.product-list-image {
  width: 100%;
  height: 120%;
  object-fit: contain;
  border-radius: 8px;
}

.product-info {
  text-align: center;
  font-family: 'Sniglet', sans-serif;
  margin-top: -60px;
}

.product-price {
  font-weight: bold;

  color: #333;
  font-family: 'Sniglet', sans-serif;
}

.product-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
 
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

@media (max-width: 1200px) {
  .product-list {
    justify-content: center;
  }

  .product-item {
    flex-basis: calc(33.33% - 20px);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: repeating-conic-gradient(#0ae300 0 90deg, #000000 0 180deg);
  animation: s1 1s infinite linear;
}

@keyframes s1 {
  100% {
    transform: rotate(0.5turn);
  }
}
.Protop{
  margin-top: 20px;
  width: 597px;
height: 88px;
flex-shrink: 0;
color: #000;
text-align: center;
font-family: Sniglet;
font-size: 50px;
font-style: normal;
font-weight: 200;
line-height: normal;

}
.Protopline{

  width: 430px;
height: 7px;
margin-left:80px;
flex-shrink: 0;
border-radius: 5px;
background: #005E89;
}
.searchbox123 {
 
  width: 400px;
  height: 70px;
  margin-left: 1000px;
}

.searchbox123 img {
  width: 100%; /* Ensure the image fills the container */
  height: 100%; /* Ensure the image fills the container */
  object-fit: fill; /* Maintain aspect ratio */
}

.search123-input {

 
  background-color:#005E89 ;
  top:500px;
  left: 80%;
  color: #fff;
  transform: translate(0%, -110%);
  width: 80%; /* Adjust input width as needed */
  height: 80%; /* Adjust input height as needed */
  padding: 10px; /* Adjust input padding as needed */
  border: none !important;
  
}
.search123-input::placeholder {
  color: white;
  font-size :35px; ; /* Set placeholder text color to white */
}

.ele{
margin-left: 5%;

height: 650px;
width:700px;

}
.im12{
  margin-top: -535px;
  object-fit: fill;
  height: 300px;
  width:115px;
  margin-left: 30%;
  
}
#imwq {
  background-color: #005E89;
 }
 
#im1q {
  margin-top: -100px; /* Adjust this value as needed */
  color: #000;
  
  text-align: center;
  font-family: Sniglet;
  font-size: 20px;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.imboxblue {
  margin-top: -210px;
  margin-left: 22%;

  width: 145px;
  height: 300px;
  background-color: #6FB8C0;

}

.small-boxes-containers {
  display: flex;
  justify-content: space-evenly;
  margin-top: -50px;
  margin-left: 30px;
  
  /* Adjust as needed for spacing between boxes */
}

.smallbox3q {
  
  width: 20px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #6FB8C0;
  margin-top: -210px;
  display: flex;
  object-fit: fill;

}
#pric1{
  width: 200.533px;
height: 35px;

flex-shrink: 0;
  color: #000;
text-align: center;
font-family: Sniglet;
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
#minI{
 
  width:65px;
  height:40px;
  border-radius: 5px;
background: #EEC361;
}
#maxI{
 
  width:65px;
  height:40px;
  border-radius: 5px;
  background: #EEC361;
}
.inpucontainer {
  display: flex;
  align-items: center;
  margin-right: 1px;
  margin-left:1px  /* Align items vertically in the center */
}

.inpucontainer label{
  margin-right: 40px;

  color:black;
}
.inpucontainer input {
  margin-right: -1px;
  margin-left:5px /* Adjust spacing between elements */
}

.sliding-range {
  width: 149px;
  height: 20px; /* Adjust height as needed */
  margin-left: 0%;
  display: flex;
  align-items: center;
  position: relative;
}

.slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 5px; /* Adjust height as needed */
  border-radius: 5px;
  background: #F2FDFD;
  outline: none;
  margin: 0;
  padding: 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Adjust thumb size */
  height: 20px; /* Adjust thumb size */
  border-radius: 50%;
  background: #EEC361;
  cursor: pointer;
  margin-top: -8px; /* Adjust vertical alignment of thumb */
}


.typbox {
  position: relative;

  width: 700px;
  height: 400px;
  margin-top: 60px;
  margin-left: -60px;
}

.typbox img {

  width: 22%;
  margin-top: -13%;
  margin-left: 100px;

   /* Ensure the image is displayed as a block element */
}

#prt {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(-50%, -40%);
  color: #fff;
  text-align: center;
  font-family: Sniglet;
  font-size: 20px;
}

.typlist {
  position: relative;
  top: -130%;
  left: 25%;
  right: auto;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.typname {
  width: 100px;
  height: 50px;
  background-color: #F2FDFD;;
  margin-bottom: 5px;
}
.myp{
  margin-top: -580px;
  margin-left: 16px;
  width: 560px;
height: 80px;
flex-shrink: 0;
}

.prout{
  background-color: #dbfcff;
}