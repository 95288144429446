@import '@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Koulen&family=Lato&family=Nunito&family=Playfair+Display:ital@1&family=Prata&family=Raleway:ital,wght@1,100&family=Roboto&family=Roboto+Condensed&family=Teko&display=swap');

@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}
.signup {
  background-color: #dbfcff;
}
.register-block {
  width: 575px;
  height: 459px;
  flex-shrink: 0;
  padding: 40px 0;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Sniglet', sans-serif;
  border-top: 20px solid #005e89;
  border-bottom: 60px solid #005e89;
  border-left: 20px solid #005e89;
  border-right: 20px solid #005e89;
  border-radius: 0px;
}

.Title {
  color: #6c6363;
  text-align: center;
  font-family: 'Sniglet', sans-serif;

  font-display: swap;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
h4.rectagles {
  width: 265px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #005e89;
  top: -5px;
  left: 110px;
  position: relative;
}
.text-center-signup {
  width: 237px;
  height: 74px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Sniglet;
  font-size: 53px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-uppercase-login {
  display: flex;
  color: rgba(48, 48, 48, 0.81);
  text-align: left;
  font-family: 'Sniglet', sans-serif;

  font-display: swap;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}
.last-msg {
  font-size: medium;
  color: #d13b21;
}

.btn-login {
  font-size: 15px;
  font-family: 'Sniglet', sans-serif;

  font-display: swap;

  width: 140px;
  height: 50px;
  border-width: 1px;
  color: #fff;
  border-color: #599bb3;
  font-weight: bold;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 10px 14px -7px #276873;
  text-shadow: 0px 1px 0px #3d768a;
  background: linear-gradient(#0066cc, #0066cc);
}

.btn-login:hover {
  background: linear-gradient(#408c99, #599bb3);
}

@import '@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.btn {
  font-family: 'Sniglet', sans-serif;
  font-display: swap;

  font-weight: 100;
  font-size: 14px;
  color: #fff;
  background-color: #0066cc;
  padding: 10px 30px;
  border: 2px solid #0066cc;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 50px;
  transition: 622ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}

.btn:hover {
  transition: 622ms;
  padding: 10px 50px;
  transform: translateY(-2px);
  background-color: #fff;
  color: #0069cc;
  border: solid 2px #0069cc;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.08);
}

.btnsgoogle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}
.btnsgoogle img {
  width: 200px;
  height: 40px;
}
