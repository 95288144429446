@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

.card-type {
  margin-top: 10px;
}

.card-type img {
  width: 48px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
@import url('https://rsms.me/inter/inter.css');

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Sniglet', sans-serif;
  }
}

body {
  margin: 0;
}
.actions {
  padding-top: 5%;
  color: #6b72d6;
  font-family: 'Sniglet', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding-bottom: 10%;
}
.line {
  width: 25px;
  height: 1px;
  background: #ccc;
}
.tick {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
h1 {
  margin-bottom: 1rem;
  font-size: 30px;
}

p {
  color: var(--color-gray);
}

hr {
  height: 1px;
  width: 100%;
  background-color: var(--color-light-gray);
  border: 0;
  margin: 2rem 0;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-lighter-gray);
  padding: 0.6rem;
  border-radius: 0.25rem;
}

.field__label {
  color: var(--color-gray);
  font-size: 0.7rem;
  font-weight: 100;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

.field__input {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}
.field:focus-within {
  border-color: #000;
}

.fields {
  display: grid;
  grid-gap: 1rem;
}
.fields--2 {
  grid-template-columns: 1fr 1fr;
}
.fields--3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.button {
  background-color: #000;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  display: block;
  color: #fff;
  width: 30%;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 0;
  cursor: pointer;
  outline: 0;
}
.button:focus-visible {
  background-color: #333;
}
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5%;
  color: #6b72d6;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10%;
  padding-right: 25%;
}

.vertical-space-container {
  flex-grow: 1;
  margin-left: auto;
  background-color: #d4e2f3;
  width: 10%;
}

.container-s {
  padding: 2vw 1rem 2;
  margin: 0 auto;
  width: 50%;
  padding-bottom: 3%;
  flex: 1;
  padding-top: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-lighter-gray);
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: d4e2f3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cont {
  display: 'flexbox';
}
