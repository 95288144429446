@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Sniglet', sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
}

.navbar {
  width: 100%;
  background-color: #f57224;
  padding: 15px 0;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.product-list-container {
  display: flex;
  width: 80%;
  margin: 20px auto;
}

.left-container {
  width: 20%;
  padding: 20px;
  background-color: #f0f0f0;
}

.right-container {
  flex-grow: 1;
  padding: 25px;
}

.label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.ReactSlider {
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
}

.ReactSlider .bar {
  background: #ddd;
  height: 100%;
}

.ReactSlider .slider {
  background: #007bff;
  height: 100%;
}

.price-display {
  font-size: 14px;
  margin-bottom: 20px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  width: calc(33.33% - 40px);
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.product:hover {
  transform: scale(1.05);
}

.product img {
  max-width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.product h3 {
  color: #333;
  margin-top: 10px;
}

.product p {
  color: #777;
}

.product button {
  background-color: #f57224;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease-in-out;
}

.product button:hover {
  background-color: #e45e1c;
}
