@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}
.html {
  background-color: #dbfcff;
}
body {
  background-color: #dbfcff;
  font-family: 'Sniglet', sans-serif;
  border: none;
}
.Signin {
  background-color: #dbfcff;
}

.signtop {
  width: 575px;
  height: 35px;
  position: relative;
  left: 0px;
}

.login-block {
  width: 575px;
  height: 459px;
  flex-shrink: 0;
  padding: 40px 0;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Sniglet', sans-serif;
  border-top: 20px solid #005e89;
  border-bottom: 60px solid #005e89;
  border-left: 20px solid #005e89;
  border-right: 20px solid #005e89;
  border-radius: 0px;
}

.text-center {
  width: 237px;
  height: 74px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Sniglet;
  font-size: 53px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
h3.rectagles {
  width: 265px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #005e89;
  top: -35px;
  left: 10px;
  position: relative;
}
.text-uppercase {
  color: rgba(48, 48, 48, 0.81);
  text-align: left;
  font-family: 'Sniglet', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
  width: 209px;
  display: flex;
}

.form-group {
  width: 639px;
  height: 44px;
  flex-shrink: 0;
  color: #bb0505;
  font-family: Sniglet;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-sec {
  padding: 50px 30px;

  background: #dbfcff;
}

.login-form input {
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: block;
  width: 100%;
  padding-bottom: 2%;
}

.login-form input[type='submit'] {
  background: #dbfcff;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;
  width: 110px;
  height: 50px;

  margin: 0 auto;
  padding-bottom: 2%;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  padding: auto;
}

.login-form input[type='submit']:hover {
  background-color: rgba(105, 60, 233, 1);
}

.login-block {
  text-decoration: none !important;
}

.form-group {
  font-size: medium;
  text-align: center;
}
.btn-signin {
  font-size: 15px;
  font-family: 'Sniglet', sans-serif;
  width: 140px;
  height: 50px;
  border-width: 1px;
  color: #fff;
  border-color: #599bb3;
  font-weight: bold;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 10px 14px -7px #276873;
  text-shadow: 0px 1px 0px #3d768a;
  background: linear-gradient(#0066cc, #0066cc);
}

.btn-signin:hover {
  background: linear-gradient(#408c99, #599bb3);
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Koulen&family=Lato&family=Nunito&family=Playfair+Display:ital@1&family=Prata&family=Raleway:ital,wght@1,100&family=Roboto&family=Roboto+Condensed&family=Teko&display=swap');

.btngoogle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}
.btnmicrosoft {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}
.btngoogle img {
  width: 200px;
  height: 40px;
}

.elementfooter {
  width: 1440px;
  height: 150px;
  flex-shrink: 0;
}
