@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

.cart {
  background-color: #dbfcff;
}

.cart-wrapper {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Sniglet', sans-serif;
}

.cart-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.cart-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
}

.cart-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.item-details {
  margin-top: 10px;
}

h3 {
  font-size: 18px;
  color: #333;
  font-family: 'Sniglet', sans-serif;
}

p {
  font-size: 14px;
  color: #666;
  font-family: 'Sniglet', sans-serif;
}

.remove-btn {
  background-color: #f64749;
  color: #fff;
  font-family: 'Sniglet', sans-serif;

  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Sniglet', sans-serif;
  margin-top: 10px;
}

.cart-summary {
  margin-top: 20px;
  border-top: 2px solid #ddd;
  padding-top: 15px;
}

.cart-summary h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Sniglet', sans-serif;
}

.checkout-btn {
  background-color: #256eff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Sniglet', sans-serif;
  font-size: 16px;
}
@keyframes l9 {
  to {
    transform: perspective(300px) translateZ(100px);
    opacity: 0;
  }
}
