/* Global Styles */
body {
    background-color: #dbfcff;
    margin: 0;
    padding: 0;
    font-family: Sniglet, sans-serif;
}

/* Responsive Image Styling */
.imhm, .bluebox1, .hmpgill, .bluecontbgimg, .drawn_leg, .drawn_leg2, .drawnlego3, .drawnlego4, .ihms, .fboxcl, .whiline {
    max-width: 100%;
    height: auto;
    width: 100%;
}

/* Container Styles */
.bluecont {
    text-align: center;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

/* Image Styling */
.bluebox1 {
    width: 100%;
    max-height: 10%; /* Adjust max-height as needed */
    margin: 0 auto;
}

.bluecontbgimg {
    width: 80%; /* Adjusted to be responsive */
    margin: 0 auto;
}

.drawn_leg {
    width: 15%; /* Width set as a percentage */
    margin: auto;
}

.drawn_leg2 {
    width: 40%; /* Adjusted width for responsive scaling */
    margin: auto;
}

.drawnlego3 {
    width: 30%; /* Responsive width */
    margin: 5% auto;
}

.drawnlego4 {
    width: 10%; /* Smaller width for smaller screens */
    margin: 10% auto;
}

/* Adjusted Text Styles for Responsiveness */
.co1, .co2, .ccont123, .frcont, .frcont2, .feattext, .few {
    color: #000;
    text-align: center;
    margin: 5% auto;
    width: 90%;
    max-width: 1200px;
    line-height: 1.5;
}

/* Specific Heading and Font Sizes */
.feattext {
    color: #2e6c97;
    font-size: 3rem;
}

.co1, .co2, .ccont123 {
    font-size: 1.25rem;
}

.frcont {
    font-size: 2.5rem;
}

.frcont2 {
    font-size: 1.5rem;
    color: #005e89;
}

.few {
    color: #70b5ba;
    font-size: 2rem;
}

/* Button Styles */
.butfrco, .ihms {
    display: flex;
    justify-content: center;
    margin: 20px auto;
}

/* Background Box Styles */
.bboxb {
    background: #7ce2f0;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
}

/* Drawn Lego and Box Layout */
.drawnlegcol {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin-top: 20px;
}

.fboxcl {
    margin: 20px auto;
    width: 85%;
}

/* Responsive Features Line */
.whiline {
    width: 80%;
    margin: 0 auto;
}

/* Responsive Typography & Container Padding */
@media (min-width: 768px) {
    .frcont {
        font-size: 3rem;
    }
    .frcont2 {
        font-size: 1.75rem;
    }
    .feattext {
        font-size: 4rem;
    }
    .co1, .co2, .ccont123 {
        font-size: 1.5rem;
    }
}

@media (max-width: 600px) {
    .frcont {
        font-size: 1.8rem;
        width: 90%;
    }
    .frcont2 {
        font-size: 1.2rem;
        width: 90%;
    }
    .feattext {
        font-size: 2rem;
        padding: 20px 0;
    }
    .co1, .co2, .ccont123 {
        font-size: 1rem;
        padding: 0 10px;
    }
}
