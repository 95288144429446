@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

body,
html {
  overflow-x: hidden;
}

.sign-up-welcome-page {
  background-color: #d4e2f3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.sign-up-welcome-page .overlap-wrapper {
  background-color: #d4e2f3;
  height: 1024px;
  width: 1440px;
}

.sign-up-welcome-page .overlap {
  height: 1013px;
  left: 20px;
  position: relative;
  top: 11px;
  width: 1420px;
}

.sign-up-welcome-page .overlap-group {
  height: 1002px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1420px;
}

.sign-up-welcome-page .rectangle {
  background-color: #898484;
  border-radius: 30px;
  height: 140px;
  left: 48px;
  position: absolute;
  top: 70px;
  width: 327px;
}

.sign-up-welcome-page .ellipse {
  background-color: #898484;
  border-radius: 73.01px/60.8px;
  height: 122px;
  left: 11px;
  position: absolute;
  top: 37px;
  width: 146px;
}

.sign-up-welcome-page .ellipse-2 {
  background-color: #898484;
  border-radius: 73.01px/60.8px;
  height: 122px;
  left: 0;
  position: absolute;
  top: 118px;
  width: 146px;
}

.sign-up-welcome-page .ellipse-3 {
  background-color: #898484;
  border-radius: 112.17px/84.77px;
  height: 170px;
  left: 129px;
  position: absolute;
  top: 0;
  width: 224px;
}

.sign-up-welcome-page .ellipse-4 {
  background-color: #898484;
  border-radius: 112.17px/84.77px;
  height: 170px;
  left: 73px;
  position: absolute;
  top: 84px;
  width: 224px;
}

.sign-up-welcome-page .ellipse-5 {
  background-color: #898484;
  border-radius: 112.17px/84.77px;
  height: 170px;
  left: 224px;
  position: absolute;
  top: 55px;
  width: 224px;
}

.sign-up-welcome-page .overlap-group-wrapper {
  height: 254px;
  left: 0;
  position: absolute;
  top: 0;
  width: 448px;
}

.sign-up-welcome-page .rectangle-2 {
  background-color: #ada8a8;
  border-radius: 30px;
  height: 140px;
  left: 48px;
  position: absolute;
  top: 70px;
  width: 327px;
}

.sign-up-welcome-page .ellipse-6 {
  background-color: #ada8a8;
  border-radius: 73.01px/60.8px;
  height: 122px;
  left: 11px;
  position: absolute;
  top: 37px;
  width: 146px;
}

.sign-up-welcome-page .ellipse-7 {
  background-color: #ada8a8;
  border-radius: 73.01px/60.8px;
  height: 122px;
  left: 0;
  position: absolute;
  top: 118px;
  width: 146px;
}

.sign-up-welcome-page .ellipse-8 {
  background-color: #ada8a8;
  border-radius: 112.17px/84.77px;
  height: 170px;
  left: 129px;
  position: absolute;
  top: 0;
  width: 224px;
}

.sign-up-welcome-page .ellipse-9 {
  background-color: #ada8a8;
  border-radius: 112.17px/84.77px;
  height: 170px;
  left: 73px;
  position: absolute;
  top: 84px;
  width: 224px;
}

.sign-up-welcome-page .ellipse-10 {
  background-color: #ada8a8;
  border-radius: 112.17px/84.77px;
  height: 170px;
  left: 224px;
  position: absolute;
  top: 55px;
  width: 224px;
}

.sign-up-welcome-page .field {
  height: 68px;
  left: 424px;
  position: absolute;
  top: 196px;
  width: 627px;
}

.sign-up-welcome-page .text-wrapper {
  color: #542bc8;
  font-family: 'Sniglet', sans-serif;
  font-size: 55px;

  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 625px;
}

.sign-up-welcome-page .p {
  color: #8775bb;
  font-family: 'Sniglet', sans-serif;

  font-size: 40px;

  font-weight: 400;
  left: 323px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 370px;
  width: 805px;
}

.sign-up-welcome-page .text-wrapper-2 {
  color: #000000;
  font-family: 'Sniglet', sans-serif;
  font-size: 30px;
  font-weight: 400;
  left: 544px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 880px;
  white-space: nowrap;
  width: 433px;
}

.sign-up-welcome-page .group {
  height: 1002px;
  left: 810px;
  position: absolute;
  top: 0;
  width: 610px;
}

.sign-up-welcome-page .line {
  height: 155px;
  left: 123px;
  position: absolute;
  top: 234px;
  width: 109px;
}

.sign-up-welcome-page .img {
  height: 149px;
  left: 155px;
  position: absolute;
  top: 247px;
  width: 99px;
}

.sign-up-welcome-page .line-2 {
  height: 104px;
  left: 81px;
  position: absolute;
  top: 381px;
  width: 143px;
}

.sign-up-welcome-page .line-3 {
  height: 71px;
  left: 87px;
  position: absolute;
  top: 476px;
  width: 141px;
}

.sign-up-welcome-page .line-4 {
  height: 76px;
  left: 146px;
  position: absolute;
  top: 471px;
  width: 85px;
}

.sign-up-welcome-page .line-5 {
  height: 91px;
  left: 151px;
  position: absolute;
  top: 389px;
  width: 106px;
}

.sign-up-welcome-page .line-6 {
  height: 81px;
  left: 121px;
  position: absolute;
  top: 393px;
  width: 109px;
}

.sign-up-welcome-page .line-7 {
  height: 52px;
  left: 123px;
  position: absolute;
  top: 471px;
  width: 70px;
}

.sign-up-welcome-page .rectangle-3 {
  height: 80px;
  left: 38px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 111px;
}

.sign-up-welcome-page .arrow {
  height: 48px;
  left: 727px;
  position: absolute;
  top: 808px;
  width: 37px;
}

.sign-up-welcome-page .rectangle-4 {
  height: 445px;
  left: 27px;
  object-fit: cover;
  position: absolute;
  top: 568px;
  width: 286px;
}

.going {
  left: 623px;
  position: absolute;
  top: 640px;
}
