@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

.order-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;

 
  height: 100vh; /* Make the container take up the full height of the viewport */

}
#odd {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  width: 778px;
  height: auto;
  flex-shrink: 0;
  height: 400px;
}
.dashboard-button {
  margin-top: 20px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #693ce9;
  color: #fff;

  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.dashboard-button:hover {
  background-color: #693ce9;
  color: #fff;
  text-decoration: none;
}
