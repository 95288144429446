@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

body {
  background: #d4e2f3;
  font-family: 'Sniglet', sans-serif;
  border: none;
}

.box {
  width: 882px;
  height: 580.042px;
  flex-shrink: 0;
  border-radius: 22px;
  border: 22px solid #ffea9e;
  background: #ffea9e;
  top: 50px;
  left: 270px;
  position: relative;
}
.returnpage {
  cursor: pointer;
}

.oops h3 {
  color: #000;
  font-family: Sniglet;
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.error h6 {
  color: #f68822;
  text-align: center;
  -webkit-text-stroke-width: 3;
  -webkit-text-stroke-color: #84460d;
  font-family: Sniglet;
  font-size: 53px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pagefound h6 {
  color: #02476c;
  text-align: center;
  font-family: Sniglet;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.work h6 {
  color: #fffbfb;
  text-align: center;
  font-family: Sniglet;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.returnpage {
  border-radius: 20px;
  background: #78d0d9;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
}

.returnpage h6 {
  color: #02476c;
  text-align: center;
  font-family: Sniglet;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.future h6 {
  color: #fff;
  font-family: Sniglet;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
