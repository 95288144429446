@font-face {
  font-family: 'Sniglet';
  src: url('/public/fonts/Sniglet-Regular.ttf') format('truetype');
}

.navbar {
  background-color: #005e89;
}

.nav-home {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.button-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  background-color: #000000; /* Button background color */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin-right: 10px; /* Add space between buttons */
}

.button-link:hover {
  color: #fff;
  background-color: #1c1b1b; /* Button hover color */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.button-link:last-child {
  margin-right: 0; /* Remove margin from the last button if necessary */
}


.store_ico {
  width: 82px;
  height: 75px;
  align-items: center;
  flex-shrink: 0;
}

.logoutbut {
  margin-top: 50px;
  width: 80px;
  height: 40px;
  margin-right: 10%;
}

.logoutbut1 {
  width: 80px;
  height: 124px;
  flex-shrink: 0;
}

.cent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 200px;
}

/* Tooltip container */
.nav-item {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip {
  visibility: hidden;
  width: 100px; /* Adjust width as needed */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 95%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -50px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(30px); /* Start with the tooltip slightly below the icon */
}

/* Arrow for the tooltip */
.tooltip::after {
  content: '';
  position: absolute;
  top: 95%; /* Arrow at the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Show tooltip on hover */
.nav-item:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateY(0); /* Slide up to the final position */
}
